import { useEffect, useState } from "react";
import { I18nProvider as LinguiI18nProvider } from "@lingui/react";
import { setupI18n } from "@lingui/core";
import {
  DEFAULT_LOCALE,
  Locale,
  ShortLocale,
  ShortToLongLocalNames,
  I18nContext,
} from "src/common/i18n";
import { getCatalog } from "src/common/catalogs";

export { Locale } from "src/common/i18n";
export { useI18n } from "src/common/i18n";

const loadLocale: Set<Locale> = new Set([]);

export const customI18n = setupI18n({
  messages: {},
});

export const useI18nLoader = (locale: Locale) => {
  useEffect(() => {
    (async () => {
      if (!loadLocale.has(locale)) {
        customI18n.load(locale, await getCatalog(locale));
        loadLocale.add(locale);
      }
      customI18n.activate(locale);
    })();
  }, [locale]);
};

const I18nProvider = ({ children }: { children: React.ReactNode }) => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);

  useEffect(() => {
    const navigatorLang = window.navigator.language;
    const navLang =
      Object.values(Locale).find((enumValue) => enumValue === navigatorLang) ||
      ShortToLongLocalNames[
        Object.values(ShortLocale).find(
          (enumValue) => enumValue === navigatorLang
        ) || "en"
      ];

    if (navLang) {
      const browserLang = navLang as Locale;
      setLocale(browserLang);
    }
  }, []);

  useI18nLoader(locale);

  // Since activating i18n is async you may see the log line:
  // "I18nProvider did not render.
  // A call to i18n.activate still needs to happen or
  // forceRenderOnLocaleChange must be set to false."

  return (
    <I18nContext.Provider value={[locale, setLocale]}>
      <LinguiI18nProvider i18n={customI18n}>{children}</LinguiI18nProvider>
    </I18nContext.Provider>
  );
};

export default I18nProvider;
