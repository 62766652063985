// DO NOT EDIT!!
// This file was automatically generated by ./scripts/generate-tracking-plan.js
export enum TrackingEvents {
  AUTOGIRO_BANKID_AUTHORISATION_CONTINUE_BUTTON_CLICKED = "Billing Request - Autogiro BankId Authorisation: Continue Button Clicked",
  AUTOGIRO_BANKID_AUTHORISATION_STARTED = "Billing Request - Autogiro BankId Authorisation: Started",
  BANK_ACCOUNT_SELECT_BANK_ACCOUNT_SELECTED = "Billing Request - Bank Account Select: Bank Account Selected",
  BANK_ACCOUNT_SELECT_PAGE_VIEWED = "Billing Request - Bank Account Select: Page Viewed",
  BANK_AUTHORISATION_BACK_BUTTON_CLICKED = "Billing Request - Bank Authorisation: Back Button Clicked",
  BANK_AUTHORISATION_CONTINUE_ON_DESKTOP_CLICKED = "Billing Request - Bank Authorisation: Continue on Desktop Clicked",
  BANK_AUTHORISATION_NEED_HELP_BUTTON_CLICKED = "Billing Request - Bank Authorisation: Need Help Button Clicked",
  BANK_AUTHORISATION_STARTED = "Billing Request - Bank Authorisation: Started",
  BANK_AUTHORISATION_STEP_FALLBACK_CLICKED = "Billing Request - Bank Authorisation Step: Fallback Clicked",
  BANK_AUTHORISATION_STEP_TRY_AGAIN_CLICKED = "Billing Request - Bank Authorisation Step: Try Again Clicked",
  BANK_DATA_ACCESS_REQUEST_ACCEPTED = "Billing Request - Bank Data Access Request: Accepted",
  BANK_DATA_ACCESS_REQUEST_PAGE_VIEWED = "Billing Request - Bank Data Access Request: Page Viewed",
  BANK_DETAILS_STEP_ACCOUNT_HOLDER_NAME_TYPED = "Billing Request - Bank Details Step: Account Holder Name Typed",
  BANK_DETAILS_STEP_ACCOUNT_NUMBER_TYPED = "Billing Request - Bank Details Step: Account Number Typed",
  BANK_DETAILS_STEP_BACK_BUTTON_CLICKED = "Billing Request - Bank Details Step: Back Button Clicked",
  BANK_DETAILS_STEP_BANK_DETAIL_TYPE_UPDATED = "Billing Request - Bank Details Step: Bank Detail Type Updated",
  BANK_DETAILS_STEP_BRANCH_CODE_TYPED = "Billing Request - Bank Details Step: Branch Code Typed",
  BANK_DETAILS_STEP_COMPLETED = "Billing Request - Bank Details Step: Completed",
  BANK_DETAILS_STEP_IBAN_TYPED = "Billing Request - Bank Details Step: IBAN Typed",
  BANK_DETAILS_STEP_OPTIONAL_VM_INITIALISED = "Billing Request - Bank Details Step: Optional VM Initialised",
  BANK_DETAILS_STEP_STARTED = "Billing Request - Bank Details Step: Started",
  CONFIRMATION_STEP_CHANGE_CUSTOMER_DETAILS_BUTTON_CLICKED = "Billing Request - Confirmation Step: Change Customer Details Button Clicked",
  CONFIRMATION_STEP_CHANGE_INSTITUTION_BUTTON_CLICKED = "Billing Request - Confirmation Step: Change Institution Button Clicked",
  CONFIRMATION_STEP_COMPLETED = "Billing Request - Confirmation Step: Completed",
  CONFIRMATION_STEP_DIRECT_DEBIT_INSTRUCTIONS_VIEWED = "Billing Request - Confirmation Step: Direct Debit Instructions Viewed",
  CONFIRMATION_STEP_ESIGN_TERMS_VIEWED = "Billing Request - Confirmation Step: ESign Terms Viewed",
  CONFIRMATION_STEP_SELF_AUTHORISATION_CLICKED = "Billing Request - Confirmation Step: Self Authorisation Clicked",
  CONFIRMATION_STEP_SELF_AUTHORISATION_FINAL_SELECTION = "Billing Request - Confirmation Step: Self Authorisation Final Selection",
  CONFIRMATION_STEP_STARTED = "Billing Request - Confirmation Step: Started",
  CONFIRMATION_STEP_WHAT_IS_GOCARDLESS_BUTTON_CLICKED = "Billing Request - Confirmation Step: What is GoCardless Button Clicked",
  CUSTOMER_DETAILS_STEP_BILLING_ADDRESS_TYPED = "Billing Request - Customer Details Step: Billing Address Typed",
  CUSTOMER_DETAILS_STEP_COMPANY_NAME_TYPED = "Billing Request - Customer Details Step: Company Name Typed",
  CUSTOMER_DETAILS_STEP_COMPLETED = "Billing Request - Customer Details Step: Completed",
  CUSTOMER_DETAILS_STEP_EMAIL_ADDRESS_TYPED = "Billing Request - Customer Details Step: Email Address Typed",
  CUSTOMER_DETAILS_STEP_FIRST_NAME_TYPED = "Billing Request - Customer Details Step: First Name Typed",
  CUSTOMER_DETAILS_STEP_LAST_NAME_TYPED = "Billing Request - Customer Details Step: Last Name Typed",
  CUSTOMER_DETAILS_STEP_MANUALLY_ENTER_ADDRESS_CLICKED = "Billing Request - Customer Details Step: Manually Enter Address Clicked",
  CUSTOMER_DETAILS_STEP_STARTED = "Billing Request - Customer Details Step: Started",
  CUSTOMER_DETAILS_STEP_USE_COMPANY_NAME_CLICKED = "Billing Request - Customer Details Step: Use Company Name Clicked",
  CUSTOMER_DETAILS_STEP_USE_PERSONAL_NAME_CLICKED = "Billing Request - Customer Details Step: Use Personal Name Clicked",
  DESCRIPTION_PANEL_EDUCATION_ACCORDION_OPEN_CLICKED = "Billing Request - Description Panel: Education Accordion Open Clicked",
  EMAIL_VERIFICATION_FAILED = "Billing Request - Email Verification: Failed",
  EMAIL_VERIFICATION_FLOW_RESUMED = "Billing Request - Email Verification: Flow Resumed",
  EMAIL_VERIFICATION_FORM_SUBMITTED = "Billing Request - Email Verification: Form Submitted",
  EMAIL_VERIFICATION_NEW_FLOW_GENERATED = "Billing Request - Email Verification: New Flow Generated",
  EMAIL_VERIFICATION_UNAUTHORISED = "Billing Request - Email Verification: Unauthorised",
  EMAIL_VERIFICATION_VIEWED = "Billing Request - Email Verification: Viewed",
  EXPERIMENTS_DECISION_CREATED = "Experiments - Decision Created",
  FOOTER_DIRECT_DEBIT_GUARANTEE_CLICKED = "Billing Request - Footer: Direct Debit Guarantee Clicked",
  GENERICERROR_DISPLAY_GENERIC_ERROR_SCREEN = "Billing Request - GenericError: Display Generic Error Screen",
  HOSTED_MANDATE_SETUP_PAGE_VIEWED = "Hosted Mandate Setup Page: Viewed",
  MOBILE_CONNECT_INTERSTITIAL_CONTINUE_BUTTON_CLICKED = "Billing Request - Mobile Connect Interstitial Continue Button Clicked",
  MOBILE_CONNECT_INTERSTITIAL_PAGE_LOADED = "Billing Request - Mobile Connect Interstitial Page Loaded",
  PAGE_VIEWED = "Billing Request - Page Viewed",
  PAYER_GROWTH_LOOP_CTA_CLICKED = "Billing Request - Payer Growth Loop CTA Clicked",
  PAYER_GROWTH_LOOP_CTA_IN_VIEWPORT = "Billing Request - Payer Growth Loop CTA In Viewport",
  PAYER_GROWTH_LOOP_VALUE_PROP_IN_VIEWPORT = "Billing Request - Payer Growth Loop Value Prop In Viewport",
  SELECT_INSTITUTION_STEP_BACK_BUTTON_CLICKED = "Billing Request - Select Institution Step: Back Button Clicked",
  SELECT_INSTITUTION_STEP_COMPLETED = "Billing Request - Select Institution Step: Completed",
  SELECT_INSTITUTION_STEP_ENTER_MANUAL_DETAILS_CLICKED = "Billing Request - Select Institution Step: Enter Manual Details Clicked",
  SELECT_INSTITUTION_STEP_FALLBACK_CLICKED = "Billing Request - Select Institution Step: Fallback Clicked",
  SELECT_INSTITUTION_STEP_SEARCH = "Billing Request - Select Institution Step: Search",
  SELECT_INSTITUTION_STEP_STARTED = "Billing Request - Select Institution Step: Started",
  SUCCESS_PAGE_VIEWED = "Billing Request - Success Page Viewed",
}
