import { Component } from "react";
import { captureException } from "src/common/sentry";

class SentryErrorBoundary extends Component<{
  children: React.ReactNode;
}> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    captureException(error, { errorInfo });
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default SentryErrorBoundary;
