import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { FieldType } from "./customer-details-config";
import { CustomerDetailsFields } from "./fields";

export const sharedPersonalFields = (): Record<string, FieldType> => ({
  [CustomerDetailsFields.GivenName]: {
    name: CustomerDetailsFields.GivenName,
    label: i18n._(
      t({
        id: "fields-config.first-name.label",
        message: "Your first name",
      })
    ),
    placeholder: "",
    instructions: "",
  },
  [CustomerDetailsFields.FamilyName]: {
    name: CustomerDetailsFields.FamilyName,
    label: i18n._(
      t({
        id: "fields-config.last-name.label",
        message: "Your last name",
      })
    ),
    placeholder: "",
    instructions: "",
  },
  [CustomerDetailsFields.CompanyName]: {
    name: CustomerDetailsFields.CompanyName,
    label: i18n._(
      t({
        id: "fields-config.company-name.label",
        message: "Your company name",
      })
    ),
    placeholder: "",
    instructions: "",
  },
});

export const sharedContactFields = (): Record<string, FieldType> => ({
  [CustomerDetailsFields.Email]: {
    name: CustomerDetailsFields.Email,
    label: i18n._(
      t({ id: "fields-config.email.label", message: "Email address" })
    ),
    placeholder: "",
    instructions: i18n._(
      t({
        id: "fields-config.email.instructions",
        message: "We'll only use this to keep you updated about your payment",
      })
    ),
  },
});

export const addressZipCodeField: Record<string, FieldType> = {
  [CustomerDetailsFields.PostalCode]: {
    name: CustomerDetailsFields.PostalCode,
    label: "Zipcode",
    placeholder: "",
    instructions: "",
  },
};

export const sharedAddressFields: Record<string, FieldType> = {
  [CustomerDetailsFields.AddressLine1]: {
    name: CustomerDetailsFields.AddressLine1,
    label: "Billing address line 1",
    placeholder: "",
    instructions: "",
    required: true,
  },
  [CustomerDetailsFields.AddressLine2]: {
    name: CustomerDetailsFields.AddressLine2,
    label: "Billing address line 2",
    placeholder: "",
    instructions: "",
    required: false,
  },
  [CustomerDetailsFields.City]: {
    name: CustomerDetailsFields.City,
    label: "Town or City",
    placeholder: "",
    instructions: "",
  },
  [CustomerDetailsFields.PostalCode]: {
    name: CustomerDetailsFields.PostalCode,
    label: "Postcode",
    placeholder: "",
    instructions: "",
  },
};
