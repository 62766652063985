import { createContext, useEffect } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { getConfig } from "src/legacy-ui/config";
import useCookie from "react-use/lib/useCookie";
import { CookieStorage } from "src/common/storage";

interface SegmentAbsProps {
  children: React.ReactNode;
}

const analytics = new AnalyticsBrowser();

export const SegmentContext = createContext<AnalyticsBrowser>(analytics);

export const SegmentAbs = ({ children }: SegmentAbsProps) => {
  const {
    client: { segment },
  } = getConfig();
  const [webAnonymousID] = useCookie(CookieStorage.WebAnonymousID);

  useEffect(() => {
    analytics.load({ writeKey: segment.key }, { obfuscate: true });
    if (webAnonymousID) {
      analytics?.setAnonymousId(webAnonymousID);
    }
  }, [segment.key, webAnonymousID]);

  return (
    <SegmentContext.Provider value={analytics}>
      {children}
    </SegmentContext.Provider>
  );
};
