export enum ConfigurationEnvironment {
  Default = "default",
  DevelopmentLiveStaging = "development-live-staging",
  DevelopmentSandboxStaging = "development-sandbox-staging",
  Development = "development",
  LiveProduction = "live-production",
  LiveStaging = "live-staging",
  Production = "production",
  SandboxProduction = "sandbox-production",
  SandboxStaging = "sandbox-staging",
  Test = "test",
}

export enum Environment {
  Development = "development",
  Staging = "staging",
  Production = "production",
}

export enum Role {
  Live = "live",
  Sandbox = "sandbox",
}
