import { Box, AlignItems, JustifyContent } from "@gocardless/flux-react";
import { getConfig } from "src/legacy-ui/config";

const LoadingGif = () => {
  const { basePath } = getConfig().shared;

  return (
    <Box
      gutterV={3}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
      layout="flex"
    >
      {/* We need to use the basePath here otherwise next doesn't find the asset
          which is sad */}
      <img
        data-testid="loading-spinner"
        src={`${basePath}/gif/spinnerlock_dark.gif`}
        alt="loading..."
        height={100}
        width={100}
      />
    </Box>
  );
};

export default LoadingGif;
