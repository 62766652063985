import { useRouter } from "next/router";
import { useEffect } from "react";
import { TrackingEvents } from "src/common/trackingEvents";
import { useSegment } from "src/shared/Segment/useSegment";

const TrackPageViews = () => {
  const router = useRouter();
  const { sendEvent } = useSegment();

  // on app load
  useEffect(() => {
    sendEvent(TrackingEvents.PAGE_VIEWED, {
      toPath: window.location.pathname,
    });
  }, []);

  // on page transitions
  useEffect(() => {
    const handleRouteChange = (
      url: string,
      { shallow }: { shallow: boolean }
    ) => {
      if (!shallow && typeof window !== "undefined") {
        const cleanUrl = new URL(url, window.location.origin).pathname;

        sendEvent(TrackingEvents.PAGE_VIEWED, {
          from_path: window.location.pathname,
          to_path: cleanUrl,
        });
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router, sendEvent]);

  return null;
};

export default TrackPageViews;
