import { useContext } from "react";
import { ga as getReactGA } from "react-ga";
import { UAParser } from "ua-parser-js";
import { Logger } from "src/common/logger";
import { captureException } from "src/common/sentry";
import { getAppConfig } from "src/common/config";

import { sessionTracking } from "./sessionTracking";

import { SegmentContext } from ".";
interface DefaultSegmentParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
interface SendEventAbsParams {
  user_language: string;
}

export const useSegmentAbs = () => {
  const log = Logger("Segment");
  const { track, identify } = useContext(SegmentContext);

  const hasSegment = !!getAppConfig()?.client?.segment?.key;

  return {
    sendEventAbs: (
      name: string,
      params?: SendEventAbsParams & DefaultSegmentParams
    ) =>
      new Promise((resolve) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ga = getReactGA() as any;
        const clientId = ga?.getAll?.()[0]?.get("clientId");
        const session_id = sessionTracking.getSession();
        const { browser, device } = new UAParser(
          navigator?.userAgent
        ).getResult();

        return hasSegment
          ? track(
              name,
              {
                browser: browser.name,
                device_type: device.type || "Desktop",
                client_id: clientId,
                session_id,
                ...params,
              },
              clientId
                ? {
                    integrations: {
                      "Google Universal Analytics": { clientId },
                    },
                  }
                : undefined,
              resolve
            ).catch((error) => {
              // Fail silently since we don't want Segment errors to interfere with
              // the user experience.
              log({
                error,
                message:
                  "error while calling attempting to make a Segment track call",
              });
              captureException(error, {});
            })
          : resolve(undefined);
      }),
    // We now call Identify to update the user traits to include extra details
    sendIdentify: (id: string, params?: DefaultSegmentParams): Promise<void> =>
      new Promise((resolve) =>
        hasSegment
          ? identify(id, { ...params }, resolve).catch((error) => {
              // Fail silently since we don't want Segment errors to interfere with
              // the user experience.
              log({
                error,
                message:
                  "error while calling attempting to make a Segment identify call",
              });
              captureException(error, {});
            })
          : resolve(undefined)
      ),
  };
};
