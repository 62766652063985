import * as Sentry from "@sentry/browser";
import { HTTPError } from "@gocardless/api/utils/api";

import getAppConfig from "./config";

const sentryOptions: Sentry.BrowserOptions = {
  dsn: getAppConfig().client.sentry.dsn,
  environment: getAppConfig().shared.environment,
  release: getAppConfig().shared.buildId,
  denyUrls: getAppConfig().client.sentry.deny,
  maxBreadcrumbs: 50,
  attachStacktrace: true,
};

// When we're developing locally
//
// TODO: Disabled for now, as it's breaking tslint
/*
if (process.env.NODE_ENV === "development") {
  // Don't actually send the errors to Sentry
  sentryOptions.beforeSend = () => null;

  // Instead, dump the errors to the console
  sentryOptions.integrations = [
    new SentryIntegrations.Debug({
      // Trigger DevTools debugger instead of using console.log
      debugger: false,
    }),
  ];
}
*/

Sentry.init(sentryOptions);

export default Sentry;

export const captureException = (
  error: Error,
  {
    errorInfo,
    httpError,
  }: { errorInfo?: React.ErrorInfo; httpError?: HTTPError }
) => {
  Sentry.withScope((scope) => {
    scope.setTag("sub_environment", getAppConfig().shared.role);
    scope.setTag("application", getAppConfig().shared.name);

    if (error.message) {
      // De-duplication currently doesn't work correctly browser errors
      // so we force deduplication by error message if it is present
      // https://docs.sentry.io/data-management/event-grouping/sdk-fingerprinting/?platform=javascript
      scope.setFingerprint([error.message]);
    }

    if (errorInfo) {
      scope.setExtras({ errorInfo });
    }

    if (httpError) {
      Sentry.setContext("HTTP Error", httpError);
    }

    return Sentry.captureException(error);
  });
};
