export enum CustomerDetailsFields {
  CustomerType = "customer_type",
  CompanyName = "company_name",
  Email = "email",
  FamilyName = "family_name",
  GivenName = "given_name",
  AddressLine1 = "address_line1",
  AddressLine2 = "address_line2",
  City = "city",
  CountryCode = "country_code",
  PostalCode = "postal_code",
  Region = "region",
  DanishIdentityNumber = "danish_identity_number",
  SwedishIdentityNumber = "swedish_identity_number",
}

export const CustomerDetailsAddressFields: CustomerDetailsFields[] = [
  CustomerDetailsFields.AddressLine1,
  CustomerDetailsFields.AddressLine2,
  CustomerDetailsFields.City,
  CustomerDetailsFields.PostalCode,
  CustomerDetailsFields.Region,
];
