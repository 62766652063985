import Head from "next/head";
import { Reset, ThemeProvider } from "@gocardless/flux-react";
import LogAppVersion from "src/legacy-ui/LogAppVersion";
import "@gocardless/flux-react/fonts.css";
import { SWRConfig } from "swr";
import SentryErrorBoundary from "src/components/shared/SentryErrorBoundary";
import { GlobalStateProvider } from "src/state/index";
import RuntimeModeInitialiser from "src/state/RuntimeModeInitialiser";
import getAppConfig, { getUrl } from "src/common/config";
import { addPrefixUrl } from "@gocardless/api/utils/api";
import { default as I18nProvider } from "src/components/shared/I18nProvider";
import { Segment } from "src/shared/Segment";
import { getConfig } from "src/legacy-ui/config";
import { Optimizely } from "src/shared/Optimizely";
import TrackPageViews from "src/shared/Segment/TrackPageViews";

// We add the prefix url to the API
addPrefixUrl(getUrl("api"));

const AppContainer = (props: EXTERNAL) => {
  const { Component, pageProps } = props;
  const { buildId, environment } = getAppConfig().shared;
  const appVersion = `${environment}:${buildId}`;
  const { basePath } = getConfig().shared;

  return (
    <SentryErrorBoundary>
      <Reset />

      <Head>
        <title>GoCardless</title>
        <meta name="app-version" content={appVersion} />
        <meta name="robots" content="noindex" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link
          rel="shortcut icon"
          type="image/jpg"
          href={`${basePath}/png/favicon-32x32.png`}
        />
      </Head>

      {/*
        Configures SWR for retry and caching. This adjusts the default
        parameters passed to the useSWR call.
        */}
      <SWRConfig
        value={{
          shouldRetryOnError: true,
          errorRetryCount: 3,
          errorRetryInterval: 100,
          revalidateOnFocus: false,
        }}
      >
        <LogAppVersion />
        <GlobalStateProvider>
          <RuntimeModeInitialiser />
          <Segment>
            <TrackPageViews />
            <I18nProvider>
              <ThemeProvider>
                <Optimizely>
                  <Component {...pageProps} />
                </Optimizely>
              </ThemeProvider>
            </I18nProvider>
          </Segment>
        </GlobalStateProvider>
      </SWRConfig>
    </SentryErrorBoundary>
  );
};

export default AppContainer;
