export enum Environment {
  development = "development",
  staging = "staging",
  production = "production",
}

export enum Role {
  live = "live",
  sandbox = "sandbox",
}
