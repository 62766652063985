import { useContext, useEffect, useRef } from "react";
import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import { getConfig } from "src/legacy-ui/config";
import { enums } from "@optimizely/optimizely-sdk";
import { GlobalState } from "src/state";
import { ShortLocalNames } from "src/common/i18n";
import { cookieDomain, CookieStorage } from "src/common/storage";
import { useSegment } from "src/shared/Segment/useSegment";
import { TrackingEvents } from "src/common/trackingEvents";

const DATAFILE_URL = `${getConfig().client.urls.api}/flags`;

const optimizely = createInstance({
  sdkKey: getConfig().client.optimizely.sdkKey,
  datafileOptions: {
    urlTemplate: DATAFILE_URL,
  },
});

interface OptimizelyProps {
  children: React.ReactNode;
}

type DecisionType = "feature-test" | "flag" | "feature";

// Optimizely does not provide a full type for the notification data provided as
// a payload when calling notification listeners. This interface should not be
// treated as exhaustive (there are other properties which can be returned in
// decisionInfo). It would also be advisable to code defensively here to ensure
// that we don't throw "cannot read property X of undefined" type errors if the
// properties change or are not correct.
interface NotificationData {
  type: DecisionType;
  userId: string;
  attributes: { [key: string]: unknown };
  decisionInfo?: {
    flagKey?: string;
    variationKey?: string;
    enabled?: boolean;
    decisionEventDispatched?: boolean;
    ruleKey?: string;
    experimentKey?: string;
    featureEnabled?: boolean;
    featureKey?: string;
  };
}

// A cache for the experiment for which we have sent decisions to Segment
// already. We don't want to send more than one decision event per session to
// avoid sending too many events, so we use this cache as a check.
// This is outside of the component as we don't want ever want updates to the
// set to cause a rerender of the component tree.
const FIRED_EVENTS = new Set<string>();

const randomId = uuidv4();
export const Optimizely = ({ children }: OptimizelyProps) => {
  const { sendEvent } = useSegment();
  const { billingRequest, locale } = useContext(GlobalState);
  const language = locale ? ShortLocalNames[locale] : "en";

  /**
   * We check to see if we know the unauthenticated OptimizelyID used on the website
   * If not we create one and make it accessible across all .gocardless domain
   */
  const environment = getConfig().shared?.environment;
  const optimizelyIDCookieDomain = cookieDomain[environment];

  const unauthenticatedOptimizelyID = Cookies.get(
    CookieStorage.PreSignupOptimizelyID
  );

  const unauthenticatedIDRef = useRef(unauthenticatedOptimizelyID || randomId);

  useEffect(() => {
    if (unauthenticatedOptimizelyID) return;

    const cookieOptions = optimizelyIDCookieDomain
      ? { domain: optimizelyIDCookieDomain }
      : undefined;
    Cookies.set(CookieStorage.PreSignupOptimizelyID, randomId, cookieOptions);
    unauthenticatedIDRef.current = randomId;
  }, [unauthenticatedOptimizelyID, optimizelyIDCookieDomain]);

  // Every time we send a decision event to Optimizely for the first time in a
  // session for an experiment, also send an event to Segment. This allows us to
  // easily create experiment cohorts in Amplitude which makes our analysis a
  // lot more easy and accurate.
  useEffect(() => {
    const listener =
      optimizely.notificationCenter.addNotificationListener<NotificationData>(
        enums.NOTIFICATION_TYPES.DECISION,
        ({ decisionInfo, type }) => {
          // We have to check here whether the decision is for a feature-test or
          // a flag as a decision event can come from either depending on how
          // the decision is being made, and the decisionInfo payloads differ
          // between the two types.
          if (type === "feature-test") {
            if (
              decisionInfo?.experimentKey &&
              decisionInfo?.variationKey &&
              !FIRED_EVENTS.has(decisionInfo?.experimentKey)
            ) {
              triggerExperimentSegmentEvent(
                decisionInfo.experimentKey,
                decisionInfo.variationKey
              );
            }
          } else if (type === "flag") {
            if (
              decisionInfo?.ruleKey &&
              decisionInfo?.variationKey &&
              decisionInfo?.decisionEventDispatched &&
              !FIRED_EVENTS.has(decisionInfo.ruleKey)
            ) {
              triggerExperimentSegmentEvent(
                decisionInfo.ruleKey,
                decisionInfo.variationKey
              );
            }
          } else if (type === "feature") {
            if (
              decisionInfo?.featureEnabled &&
              decisionInfo?.featureKey &&
              !FIRED_EVENTS.has(decisionInfo?.featureKey)
            ) {
              triggerExperimentSegmentEvent(
                decisionInfo.featureKey,
                decisionInfo.featureKey
              );
            }
          }
        }
      );
    return () => {
      optimizely.notificationCenter.removeNotificationListener(listener);
    };
  }, []);

  const triggerExperimentSegmentEvent = (
    experimentName: string,
    variationName: string
  ) => {
    sendEvent(TrackingEvents.EXPERIMENTS_DECISION_CREATED, {
      experiment_name: experimentName,
      variation_name: variationName,
    });
    FIRED_EVENTS.add(experimentName);
  };

  if (
    billingRequest?.links &&
    billingRequest?.id &&
    billingRequest.links.customer
  ) {
    return (
      <OptimizelyProvider
        optimizely={optimizely}
        user={{
          id: billingRequest.links.customer as string,
          attributes: {
            creditor_id: billingRequest.links.creditor as string,
            billing_request_id: billingRequest?.id,
            organisation_id: billingRequest.links.organisation as string,
            language,
            anonymous_client_id: unauthenticatedIDRef.current,
          },
        }}
      >
        {children}
      </OptimizelyProvider>
    );
  }

  return (
    <OptimizelyProvider optimizely={optimizely}>{children}</OptimizelyProvider>
  );
};
