import { createContext, useContext } from "react";

export interface AlternativeLangs {
  [locale: string]: string | undefined;
}

export enum Locale {
  DA_DK = "da-DK",
  DE_DE = "de-DE",
  EN_GB = "en-GB",
  ES_ES = "es-ES",
  FR_FR = "fr-FR",
  IT_IT = "it-IT",
  NB_NO = "nb-NO",
  NL_NL = "nl-NL",
  PT_PT = "pt-PT",
  SL_SI = "sl-SI",
  SV_SE = "sv-SE",
}

export enum ShortLocale {
  DA = "da",
  DE = "de",
  EN = "en",
  ES = "es",
  FR = "fr",
  IT = "it",
  NB = "nb",
  NL = "nl",
  PT = "pt",
  SL = "sl",
  SV = "sv",
}

export const ShortLocalNames: Record<Locale, string> = {
  [Locale.DA_DK]: ShortLocale.DA,
  [Locale.DE_DE]: ShortLocale.DE,
  [Locale.EN_GB]: ShortLocale.EN,
  [Locale.ES_ES]: ShortLocale.ES,
  [Locale.FR_FR]: ShortLocale.FR,
  [Locale.IT_IT]: ShortLocale.IT,
  [Locale.NB_NO]: ShortLocale.NB,
  [Locale.NL_NL]: ShortLocale.NL,
  [Locale.PT_PT]: ShortLocale.PT,
  [Locale.SL_SI]: ShortLocale.SL,
  [Locale.SV_SE]: ShortLocale.SV,
};

export const ShortToLongLocalNames: Record<string, Locale> = {
  [ShortLocale.DA]: Locale.DA_DK,
  [ShortLocale.DE]: Locale.DE_DE,
  [ShortLocale.EN]: Locale.EN_GB,
  [ShortLocale.ES]: Locale.ES_ES,
  [ShortLocale.FR]: Locale.FR_FR,
  [ShortLocale.IT]: Locale.IT_IT,
  [ShortLocale.NB]: Locale.NB_NO,
  [ShortLocale.NL]: Locale.NL_NL,
  [ShortLocale.PT]: Locale.PT_PT,
  [ShortLocale.SL]: Locale.SL_SI,
  [ShortLocale.SV]: Locale.SV_SE,
};

export const DEFAULT_LOCALE = Locale.EN_GB;

export const LocaleNames: Record<Locale, string> = {
  [Locale.DA_DK]: "Dansk",
  [Locale.DE_DE]: "Deutsch",
  [Locale.EN_GB]: "English",
  [Locale.ES_ES]: "Español",
  [Locale.FR_FR]: "Français",
  [Locale.IT_IT]: "Italiano",
  [Locale.NB_NO]: "Norsk",
  [Locale.NL_NL]: "Nederlands",
  [Locale.PT_PT]: "Português",
  [Locale.SL_SI]: "Slovenščina",
  [Locale.SV_SE]: "Svenska",
};

export interface LocaleContextValue {
  locale: string;
  alternativeLangs: AlternativeLangs;
}

export const LocaleContext = createContext<LocaleContextValue>({
  locale: DEFAULT_LOCALE,
  alternativeLangs: {},
});

type I18nReturnState = [Locale, (locale: Locale) => void];

export const I18nContext = createContext<I18nReturnState>([
  DEFAULT_LOCALE,
  () => DEFAULT_LOCALE,
]);

export const useI18n = (): I18nReturnState => {
  return useContext(I18nContext);
};
