import { useContext, useEffect } from "react";
import { useRouter } from "next/router";

import { GlobalState } from ".";

// Different modes of running the app, set in the global state so components can
// adapt.
export enum RuntimeMode {
  // Whenever we're running in the full browser, as part of a redirect flow.
  Hosted = "hosted",
  // Launched as part of the dropin modal, so we should act like a modal
  // (transparent background, etc)
  Dropin = "dropin",
}

// Queries URL parameters to initialise the runtime mode on the global state.
// This mode can be used to alter component styles.
const RuntimeModeInitialiser = () => {
  const router = useRouter();
  const { setRuntimeMode } = useContext(GlobalState);

  useEffect(() => {
    const mode = router.query.mode as RuntimeMode;
    if (mode && Object.values(RuntimeMode).includes(mode)) {
      return setRuntimeMode(mode);
    }

    setRuntimeMode(RuntimeMode.Hosted);
  }, [router, setRuntimeMode]);

  return <></>;
};

export default RuntimeModeInitialiser;
