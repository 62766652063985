import { useContext } from "react";
import { GlobalState } from "src/state";
import { getPaymentVariant } from "src/common/payments";
import { SEGMENT_REDIRECT_TIMEOUT_MS } from "src/common/consts";

import { useSegmentAbs } from "../SegmentAbs/useSegmentAbs";

export interface Segment {
  sendEvent: (name: string, params?: {}) => void;
  sendEventAndRedirect: (
    name: string,
    callback: () => void,
    params?: {}
  ) => void;
}

export const useSegment = (): Segment => {
  const { sendEventAbs } = useSegmentAbs();
  const {
    billingRequest,
    billingRequestFlow,
    billingRequestId,
    paylink,
    plan,
    runtimeMode,
  } = useContext(GlobalState);
  const creditor_id = billingRequest?.links?.creditor;
  const organisation_id = billingRequest?.links?.organisation;

  const schemes = {
    payment_scheme: billingRequest?.payment_request?.scheme,
    mandate_scheme: billingRequest?.mandate_request?.scheme,
  };
  const funds_settlement_type =
    billingRequest?.payment_request?.funds_settlement;
  const payment_product_type =
    billingRequest && getPaymentVariant(billingRequest, paylink);

  const sendSegmentEvent = async (name: string, params?: {}) => {
    await sendEventAbs(name, {
      ...params,
      ...schemes,
      billing_request_id: billingRequestId,
      creditor_id,
      funds_settlement_type,
      organisation_id,
      paylink_id: paylink?.id,
      payment_product_type,
      plan_id: plan?.id,
      redirect_origin: billingRequestFlow?.redirect_origin,
      runtimeMode,
      user_language: navigator.language,
      experiments: billingRequest?.experimentation,
    });
  };

  return {
    sendEvent: (name, params?) => sendSegmentEvent(name, params),

    // for any events followed by a page redirect, we use a .5 sec timeout
    // to always proceed to the redirected page so that any Segment failures
    // don't impact the user flow
    sendEventAndRedirect: async (name, callback, params?) => {
      const redirectTimeout = setTimeout(() => {
        callback();
      }, SEGMENT_REDIRECT_TIMEOUT_MS);

      await sendSegmentEvent(name, params);

      clearTimeout(redirectTimeout);
      callback();
    },
  };
};
