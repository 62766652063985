import getNextConfig from "next/config";

import { ConfigurationEnvironment, Environment, Role } from "./environments";
export { Environment, Role };

declare interface NextConfig {
  publicRuntimeConfig: { __APP_CONFIG__: AppConfig };
}

export const getConfig = (): AppConfig => {
  const {
    publicRuntimeConfig: { __APP_CONFIG__ },
  }: NextConfig = getNextConfig();

  return __APP_CONFIG__;
};

export const getFeatureFlag = (name: string) => {
  const {
    shared: { flags = {} },
  } = getConfig();

  return Boolean(flags[name]);
};

export const getUrl = (name: string) => {
  const {
    client: { urls },
  } = getConfig();
  return urls[name] || "";
};

export const getSite = () => getConfig().shared.site;

export const isEnvironment = (environment: Environment) =>
  environment === getConfig().shared.environment;

export const isRoleAndEnvironment = (
  roleAndEnvironment: ConfigurationEnvironment
) => roleAndEnvironment === getConfig().shared.roleAndEnvironment;

export const isRole = (role: Role) => getConfig().shared.role === role;
