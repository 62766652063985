import getConfig from "next/config";

import { Environment, Role } from "./environments";

declare interface NextConfig {
  publicRuntimeConfig: { __APP_CONFIG__: AppConfig };
}

export const getAppConfig = (): AppConfig => {
  const {
    publicRuntimeConfig: { __APP_CONFIG__ },
  }: NextConfig = getConfig();

  return __APP_CONFIG__;
};

export const getUrl = (name: string) => {
  const {
    client: { urls },
  } = getAppConfig();
  return urls[name] || "";
};

export const isEnvironment = (environment: Environment) =>
  environment === getAppConfig().shared.environment;

export const isRole = (role: Role) => getAppConfig().shared.role === role;

export default getAppConfig;
