import { InstitutionResource } from "@gocardless/api/dashboard/types";

export enum BankGB {
  barclays = "BARCLAYS_BUKBGB22",
  barclays_business = "BARCLAYS_BUSINESS_BUKBGB22",
  barclays_corporate = "BARCLAYS_CORPORATE_BUKBGB22",
  co_operative = "co_operative",
  coutts = "COUTTS_COUTGB22XXX",
  danske_business = "DANSKEBANK_BUSINESS_DABAGB2B",
  firstdirect = "FIRST_DIRECT_MIDLGB22",
  halifax = "HALIFAX_HLFXGB22",
  hsbc = "HSBC_HBUKGB4B",
  hsbc_business = "HSBC_BUSINESS_HBUKGB4B",
  lloyds_business = "LLOYDS_BUSINESS_LOYDGB2L",
  lloyds = "LLOYDS_LOYDGB2L",
  metro_bank = "metro_bank",
  monzo = "MONZO_MONZGB2L",
  national_westminster = "NATWEST_NWBKGB2L",
  nationwide = "NATIONWIDE_NAIAGB21",
  royal_bank_of_scotland = "RBS_GB_RBSSGBKC",
  royal_bank_of_scotland_corp = "RBS_GB_CORP_RBSSGBKC",
  santander = "SANTANDER_GB_ABBYGB2L",
  starling = "STARLING_SRLGGB3L",
  tsb = "TSB_GB_TSBSGB2A",
  yorkshire = "yorkshire",
  santander_uk = "SANTANDER_GB_ABBYGB2L",
  hsbc_uk = "HSBC_HBUKGB4B",
  natwest = "NATWEST_NWBKGB2L",
  natwest_corporate = "NATWEST_CORP_NWBKGB2L",
  bank_of_scotland = "BANK_OF_SCOTLAND_BOFSGBS1",
  bank_of_scotland_business = "BANK_OF_SCOTLAND_BUSINESS_BOFSGBS1",
  revolut = "REVOLUT_REVOGB21",
  virgin_money = "VIRGIN_NRNBGB22",
}

export enum BankDE {
  // popular personal banks
  postbank_client_de = "POSTBANK_PBNKDEFFXXX",
  dkb_client_de = "DKB_BYLADEM1",
  ing_oauth_client_de = "ING_INGDDEFF",
  deutsche_bank_client_de = "DEUTSCHE_BANK_DEUTDEFF",
  commerzbank_oauth_client_de = "COMMERZBANK_COBADEFF",
  n26_xs2a_oauth_client_de = "N26_NTSBDEB1",
  berliner_sparkasse_oauth_client_de = "BERLINER_SPARKASSE_BELADEBEXXX",
  comdirect_oauth_client_de = "COMDIRECT_COBADEHD",
  // additional popular business banks
  unicredit_oauth_client_de = "UNICREDIT_EBANKING_HYVEDEMM",
}

export enum BankFR {
  // popular personal banks
  banque_postale = "LABANQUEPOSTALE_PSSTFRPP",
  societe_generale = "SG_SOGEFRPP",
  bnp_paribas = "BNP_PARIBAS_BNPAFRPPXXX",
  lcl = "LCL_CRLYFRPP",
  boursorama = "BOURSORAMA_BOUSFRPP",
  caisse_depargne_ile_de_france = "CAISSEDEPARGNE_ILE_DE_FRANCE_CEPAFRPP751",
  caisse_depargne_hautse_de_france = "CAISSEDEPARGNE_HAUTS_DE_FRANCE_CEPAFRPP627",
  credit_agricole_paris = "AGRICOLE_PARIS_AGRIFRPPXXX",
  // additional popular business banks
  bnp_paribas_enterprise = "BNP_PARIBAS_ENTREPRISE_BNPAFRPPXXX",
  societe_generale_medium_enterprise = "SG_MEDIUM_ENTERPRISE_SOGEFRPP",
  societe_generale_small_enterprise = "SG_SMALL_ENTERPRISE_SOGEFRPP",
  banque_postale_business = "1304_FR_XXX",
  lcl_corporate = "LCL_CORPORATE_CRLYFRPP",
  cic_banque_privee = "CIC_BANQUE_PRIVEE_CMCIFRPPXXX",
}

export enum BankUS {
  chase = "mx_chase",
  bank_of_america = "mx_bank_of_america",
  wells_fargo = "mx_wells_fargo",
  citibank = "mx_citibank",
  capital_one = "mx_capital_one",
}

// We need BankLogoImageNameGB to show the black & white logo next to
// the sort code in Bacs. We do not show such a logo next to the IBAN in SEPA
// so there are no such images that exist.
export enum BankLogoImageNameGB {
  BarclaysLogo = "BarclaysLogo",
  CoOperativeLogo = "CoOperativeLogo",
  CouttsLogo = "CouttsLogo",
  FirstdirectLogo = "FirstdirectLogo",
  HalifaxLogo = "HalifaxLogo",
  HSBCLogo = "HSBCLogo",
  LloydsLogo = "LloydsLogo",
  MetroBankLogo = "MetroBankLogo",
  MonzoLogo = "MonzoLogo",
  NationalWestminsterLogo = "NationalWestminsterLogo",
  NationwideLogo = "NationwideLogo",
  RBSLogo = "RBSLogo",
  SantanderLogo = "SantanderLogo",
  StarlingLogo = "StarlingLogo",
  TSBLogo = "TSBLogo",
  YorkshireLogo = "YorkshireLogo",
  SantanderUKLogo = "SantanderLogo",
  HSBCUKLogo = "HSBCLogo",
  NatwestLogo = "NatwestLogo",
  BankOfScotlandLogo = "BankOfScotlandLogo",
  RevolutLogo = "RevolutLogo",
  DanskeBankLogo = "DanskeBankLogo",
  VirginMoneyLogo = "VirginMoneyLogo",
}

export const BankLogoGB: Record<BankGB, BankLogoImageNameGB> = {
  [BankGB.barclays]: BankLogoImageNameGB.BarclaysLogo,
  [BankGB.barclays_business]: BankLogoImageNameGB.BarclaysLogo,
  [BankGB.barclays_corporate]: BankLogoImageNameGB.BarclaysLogo,
  [BankGB.co_operative]: BankLogoImageNameGB.CoOperativeLogo,
  [BankGB.coutts]: BankLogoImageNameGB.CouttsLogo,
  [BankGB.firstdirect]: BankLogoImageNameGB.FirstdirectLogo,
  [BankGB.halifax]: BankLogoImageNameGB.HalifaxLogo,
  [BankGB.hsbc]: BankLogoImageNameGB.HSBCLogo,
  [BankGB.hsbc_business]: BankLogoImageNameGB.HSBCLogo,
  [BankGB.lloyds]: BankLogoImageNameGB.LloydsLogo,
  [BankGB.lloyds_business]: BankLogoImageNameGB.LloydsLogo,
  [BankGB.metro_bank]: BankLogoImageNameGB.MetroBankLogo,
  [BankGB.monzo]: BankLogoImageNameGB.MonzoLogo,
  [BankGB.national_westminster]: BankLogoImageNameGB.NatwestLogo,
  [BankGB.nationwide]: BankLogoImageNameGB.NationwideLogo,
  [BankGB.royal_bank_of_scotland]: BankLogoImageNameGB.RBSLogo,
  [BankGB.royal_bank_of_scotland_corp]: BankLogoImageNameGB.RBSLogo,
  [BankGB.santander]: BankLogoImageNameGB.SantanderLogo,
  [BankGB.starling]: BankLogoImageNameGB.StarlingLogo,
  [BankGB.tsb]: BankLogoImageNameGB.TSBLogo,
  [BankGB.yorkshire]: BankLogoImageNameGB.YorkshireLogo,
  // BankGB.santander_uk, BankGB.hsbc_uk, BankGB.natwest are all duplicate
  // string keys, and so need to be made unique in order to correctly show
  // different logos.
  // [BankGB.santander_uk]: BankLogoImageNameGB.SantanderUKLogo,
  // [BankGB.hsbc_uk]: BankLogoImageNameGB.HSBCUKLogo,
  // [BankGB.natwest]: BankLogoImageNameGB.NatwestLogo,
  [BankGB.natwest_corporate]: BankLogoImageNameGB.NatwestLogo,
  [BankGB.bank_of_scotland]: BankLogoImageNameGB.BankOfScotlandLogo,
  [BankGB.bank_of_scotland_business]: BankLogoImageNameGB.BankOfScotlandLogo,
  [BankGB.revolut]: BankLogoImageNameGB.RevolutLogo,
  [BankGB.danske_business]: BankLogoImageNameGB.DanskeBankLogo,
  [BankGB.virgin_money]: BankLogoImageNameGB.VirginMoneyLogo,
};

const getInitialsFromName = (name: string) => {
  // this is an override.
  if (name.includes("Royal Bank of Scotland")) {
    return "rbs";
  }

  return name
    .trim()
    .split(/\s+/) // split words
    .filter(Boolean) // Remove any empty strings
    .map((x) => x[0]?.toLowerCase()) // Ensure initials are lowercase
    .join("");
};

export const getBanksWithInitials = (banks: InstitutionResource[]) =>
  banks.map((bank) => ({
    ...bank,
    initials: bank.name ? getInitialsFromName(bank.name) : "",
  }));
