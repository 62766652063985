/* eslint-disable import/no-unresolved */
import { Locale } from "./i18n";

/**
 * This indicates to Webpack 5 that .json files with @lingui/loader import must be
 * loaded with JS parser/generator.
 * https://github.com/lingui/js-lingui/issues/1048
 */
const catalogImports = {
  [Locale.DA_DK]: () =>
    import("@lingui/loader!../../catalogs/da-DK/messages.json?raw-lingui"),
  [Locale.DE_DE]: () =>
    import("@lingui/loader!../../catalogs/de-DE/messages.json?raw-lingui"),
  [Locale.EN_GB]: () =>
    import("@lingui/loader!../../catalogs/en-GB/messages.json?raw-lingui"),
  [Locale.ES_ES]: () =>
    import("@lingui/loader!../../catalogs/es-ES/messages.json?raw-lingui"),
  [Locale.FR_FR]: () =>
    import("@lingui/loader!../../catalogs/fr-FR/messages.json?raw-lingui"),
  [Locale.IT_IT]: () =>
    import("@lingui/loader!../../catalogs/it-IT/messages.json?raw-lingui"),
  [Locale.NB_NO]: () =>
    import("@lingui/loader!../../catalogs/nb-NO/messages.json?raw-lingui"),
  [Locale.NL_NL]: () =>
    import("@lingui/loader!../../catalogs/nl-NL/messages.json?raw-lingui"),
  [Locale.PT_PT]: () =>
    import("@lingui/loader!../../catalogs/pt-PT/messages.json?raw-lingui"),
  [Locale.SL_SI]: () =>
    import("@lingui/loader!../../catalogs/sl-SI/messages.json?raw-lingui"),
  [Locale.SV_SE]: () =>
    import("@lingui/loader!../../catalogs/sv-SE/messages.json?raw-lingui"),
};

export const getCatalog = async (locale: Locale) => {
  const catalogImport = catalogImports[locale] || catalogImports[Locale.EN_GB];
  const catalogModule = await catalogImport();
  return catalogModule.messages;
};
